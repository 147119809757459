import {Growth, Period, PolicyFilter, RenewalStatus} from 'modules/decisionsNext/api/types';

import type {FormatterFn} from 'utils/formatters';

export type IndustryGroupChild = IndustryGroup | Industry;

export type IndustryGroup = {
  __typename?: 'IndustryGroup';
  id: string;
  name: string;
  parent?: IndustryGroup | null;
  children: IndustryGroupChild[];
  industryCount: number;
};

export type Industry = {
  __typename?: 'Industry';
  id: string;
  name: string;
  naics: string;
  sic: string[];
  parent: IndustryGroup;
};

/**
 * @deprecated
 */
export type KpiBarDataItem = {
  value: any;
  growth?: Growth['diff'];
};

export type KpiBarDataObject = Record<string, KpiBarDataItem>;

export type KpiBarMetrics = {
  [key: string]: {
    title: string;
    formatter: FormatterFn;
    tooltipFormatter?: FormatterFn;
    showZero?: boolean;
    withDivider?: boolean;
  };
};

export enum Screens {
  Client = 'client',
  UltimateIntermediary = 'ultimate-intermediary',
  ParentIntermediary = 'parent-intermediary',
  ChildIntermediary = 'child-intermediary',
  Intermediaries = 'intermediaries',
  Industries = 'industries',
  Industry = 'industry',
  IndustryGroup = 'industry-group',
  IndustrySubGroup = 'industry-subgroup',
  UltimateCarrier = 'market',
  ParentCarrier = 'parent-carrier',
  IssuingPaperCarrier = 'issuing-paper',
  Markets = 'markets',
  Producer = 'producer',
  Producers = 'producers',
  NewBusinessProducer = 'new-business-producer',
  Product = 'product',
  ProductGroup = 'product-group',
  ProductSuperGroup = 'product-super-group',
  ProductInsuranceSegment = 'product-insurance-segment',
  Products = 'products',
}

export enum TabElements {
  All = 'all',
  CommercialLines = 'commercial-lines',
  EmployeeBenefits = 'employee-benefits',
  Industry = 'industry',
  Intermediary = 'intermediary',
  Lost = 'lost',
  LostBusiness = 'lost-business',
  Form5500 = 'form5500',
  Market = 'market',
  Overview = 'overview',
  PersonalLines = 'personal-lines',
  Placement = 'placement',
  Product = 'product',
  Producer = 'producer',
  Ratings = 'ratings',
  Renewed = 'renewed',
  Type = 'type',
  Client = 'client',
  Clients = 'clients',
  Policies = 'policies',
  ProductionCredit = 'production-credit',
  BrokerAgency = 'broker-agency',
  Monoline = 'monoline',
  TargetIndustry = 'target-industry',
  Prospects = 'prospects',
  WinBack = 'win-back',
  CrossSell = 'cross-sell',
}

export type CategoryKey = 'revenue' | 'premium' | 'client';

export type Category<T = CategoryKey> = {
  label: string;
  key: T;
  formatValue: FormatterFn;
  formatSummaryValue?: FormatterFn;
};

export type PolicyFilterSingular = {
  ultimateCarrierId?: string;
  parentCarrierId?: string;
  issuingPaperId?: string;
  ultimateIntermediaryId?: string;
  parentIntermediaryId?: string;
  childIntermediaryId?: string;
  clientId?: string;
  industryGroupId?: string;
  industryId?: string;
  industrySubGroupId?: string;
  productGroupId?: string;
  productId?: string;
  productInsuranceSegmentId?: string;
  productSuperGroupId?: string;
  isCrossSell?: boolean;
  isRewrite?: boolean;
  isLost?: boolean;
  direct?: boolean;
  period?: Period;
  renewalStatus?: RenewalStatus;
};

export type FilterProps = {
  filter?: PolicyFilter;
};

export enum PageIds {
  // ClientsAndPolicies = 'ClientsAndPolicies',
  // ContingencyTracker = 'ContingencyTracker',
  ProducerGoals = 'ProducerGoals',
  ProducerGoalsSingle = 'ProducerGoalsSingle',
  Retention = 'Retention',
}

export interface QuickLinkDef {
  id?: PageIds;
  title: string;
  to?: string;
  featureFlag?: string;
  disabled?: boolean;
}

export type TabType<T> = {
  id: T;
  title: string;
};
