import {createWithEqualityFn} from 'zustand/traditional';
import {createJSONStorage, persist, subscribeWithSelector} from 'zustand/middleware';
import {shallow} from 'zustand/shallow';

import {FeatureFlag, LocalStorageKey} from 'core/types';
import {FeatureFlagData, Modifier, ModifyState} from 'modules/modifier/types';

import {updateModifiers} from 'utils/serviceWorkerInit';
import {IS_DEV_ENV} from 'config';

export const useModifier = createWithEqualityFn<ModifyState>()(
  subscribeWithSelector(
    persist<ModifyState>(
      () => ({
        modifiers: [],
        featureFlags: {},
      }),
      {
        name: LocalStorageKey.Modifiers,
        version: 1,
        storage: createJSONStorage(() => (IS_DEV_ENV ? localStorage : sessionStorage)),
        migrate(persistedState, version) {
          const store = persistedState as ModifyState;

          if (version === 0) {
            const previousStore = persistedState as Omit<ModifyState, 'featureFlags'> & {
              featureFlags: Record<string, boolean>;
            };

            return {
              ...store,
              featureFlags: Object.keys(store.featureFlags).reduce(
                (acc, key) => ({
                  ...acc,
                  [key]: {
                    enabled: previousStore.featureFlags[key],
                    activeAfterUtcDate: undefined,
                  },
                }),
                {}
              ),
            };
          }

          return store;
        },
      }
    )
  ),
  shallow
);

useModifier.subscribe(
  state => state.modifiers,
  modifiers => updateModifiers(modifiers)
);

export const addModifier = (item: Omit<Modifier, 'timestamp'>) => {
  const timestamp = Date.now();
  useModifier.setState(state => ({
    modifiers: [...state.modifiers.filter(item => item.timestamp !== timestamp), {...item, timestamp}],
  }));
};

export const editModifier = (timestamp: Modifier['timestamp'], item: Partial<Omit<Modifier, 'timestamp'>>) => {
  const original = useModifier.getState().modifiers.find(item => item.timestamp === timestamp);

  if (!original) {
    return;
  }

  useModifier.setState(state => ({
    modifiers: [...state.modifiers.filter(item => item.timestamp !== timestamp), {...original, ...item}],
  }));
};

export const toggleModifier = (timestamp: Modifier['timestamp']) => {
  useModifier.setState(state => ({
    modifiers: state.modifiers.map(item => (item.timestamp === timestamp ? {...item, active: !item.active} : item)),
  }));
};

export const deleteModifier = (timestamp: Modifier['timestamp']) => {
  useModifier.setState(state => ({
    modifiers: state.modifiers.filter(item => item.timestamp !== timestamp),
  }));
};

export const modifyFeatureFlag = (name: FeatureFlag['name'], data: FeatureFlagData | undefined) => {
  useModifier.setState(state => {
    const {[name]: _, ...rest} = state.featureFlags;

    return {
      featureFlags:
        data?.enabled === undefined && !data?.activeAfterUtcDate
          ? rest
          : {
              ...rest,
              [name]: {enabled: data.enabled, activeAfterUtcDate: data?.activeAfterUtcDate},
            },
    };
  });
};
