import {useCallback} from 'react';
import {useShallow} from 'zustand/react/shallow';

import {FeatureFlag} from 'core/types';
import {EnnablRoleType} from 'modules/command/api/types';
import {AppPermissionId} from 'modules/decisionsNext/api/types';

import {FeatureFlags} from 'core/constants/featureFlags';
import {AuthState, useAuthStore} from 'core/stores/auth';
import {IS_DEV_ENV} from 'config';
import {fetchLocalFeatureFlags} from 'modules/modifier';
import {dayjs} from 'utils/dateUtils';

export const useUserInfo = () => useAuthStore(state => state.userInfo) as Exclude<AuthState['userInfo'], undefined>;

export const useAppPermissions = () => {
  const appPermissions = useAuthStore(state => state.userInfo?.permissions);

  return {
    hasPermission: useCallback(
      (permissions: AppPermissionId | AppPermissionId[]) => {
        const permissionsArr = Array.isArray(permissions) ? permissions : [permissions];
        return permissionsArr.every(permission => appPermissions?.includes(permission));
      },
      [appPermissions]
    ),
  };
};

export const useOnlyReportStudioPermission = () => {
  const {hasPermission} = useAppPermissions();
  return hasPermission(AppPermissionId.OnlyReportStudio);
};

export const useOnlyLossRunsPermission = () => {
  const {hasPermission} = useAppPermissions();
  return hasPermission(AppPermissionId.OnlyLossRuns);
};

const checkFeatureFlagInternal = (tenantId: string, name: FeatureFlag['name'], flag?: FeatureFlag) => {
  if (IS_DEV_ENV) {
    const {[name]: modified} = fetchLocalFeatureFlags();

    if (modified !== undefined) {
      const utcDate = dayjs(modified.activeAfterUtcDate);
      return modified.activeAfterUtcDate ? modified.enabled && dayjs().utc().isAfter(utcDate) : modified.enabled;
    }

    return flag?.enabledOnDev !== false;
  }

  if (!flag) {
    return false;
  }

  const utcDate = dayjs(flag.activeAfterUtcDate);
  const enabled = flag.activeAfterUtcDate ? flag.enabled && dayjs().utc().isAfter(utcDate) : flag.enabled;

  if (tenantId && enabled) {
    if (flag.activeForTenants) {
      return flag.activeForTenants.includes(tenantId);
    }

    if (flag.inactiveForTenants?.includes(tenantId)) {
      return false;
    }
  }

  return enabled;
};

export const useFeatureFlag = (name: FeatureFlag['name']): boolean => {
  const userInfo = useUserInfo();

  const flag = useAuthStore(useShallow(state => state.flags?.find(flag => flag.name === name)));

  return checkFeatureFlagInternal(userInfo?.tenantId, name, flag);
};

/**
 * @deprecated Non-reactive getter, use only in actions
 */
export const checkFeatureFlag = (name: FeatureFlag['name']) => {
  const tenantId = useAuthStore.getState().userInfo?.tenantId as string;
  const flag = useAuthStore.getState().flags?.find(flag => flag.name === name);

  return checkFeatureFlagInternal(tenantId, name, flag);
};

export const useTenantFeatureFlag = (name: FeatureFlag['name']): boolean => {
  const {tenantId} = useUserInfo();
  const flag = useAuthStore(useShallow(state => state.flags?.find(flag => flag.name === name)));

  if (!flag) {
    return false;
  }

  return flag?.meta?.includes(tenantId) ?? false;
};

export const useIsExportHidden = () => {
  const {hasPermission} = useAppPermissions();

  return !hasPermission(AppPermissionId.ExportDecisions);
};

const useIsRoleType = (roleType: EnnablRoleType) =>
  useAuthStore(useShallow(state => state.userInfo?.roleType === roleType));

/** Admin with Admin/Custom roles */
export const useIsAdmin = () => {
  const isAdminRole = useIsRoleType(EnnablRoleType.Admin);
  const isCustomRole = useIsRoleType(EnnablRoleType.Custom);

  return isAdminRole || isCustomRole;
};

export const useIsAdminOnly = () => useIsRoleType(EnnablRoleType.Admin);
export const useIsProducer = () => useIsRoleType(EnnablRoleType.Producer);
export const useIsAccountManager = () => useIsRoleType(EnnablRoleType.AccountManager);

export const useWithEnnablAI = () => {
  const isAdmin = useIsAdminOnly();

  return {isAIEnabled: true, isAdmin};
};

const firstDecemberUtc = dayjs('2024-12-01T00:00').utc(true);

export const useWithSeparateLocationSearch = () => {
  const isAfterFirstDecember = dayjs().utc().isAfter(firstDecemberUtc);

  return useFeatureFlag(FeatureFlags.LocationSearchSeparateRequests) && isAfterFirstDecember;
};
