import LogRocket from 'logrocket';
import {isObject} from 'lodash/fp';
import {shallow} from 'zustand/shallow';

import {LocalStorageKey} from 'core/types';

import {DATE_FORMAT_ISO8601_LOCAL, dayjs} from 'utils/dateUtils';
import {IS_DEV_ENV, IS_PROD_ENV} from 'config';
import {parseJSON} from 'utils/json';

type LoggerType = 'console' | 'logrocket';

type LoggerConfig = {
  additionalLoggers: LoggerType[];
  skipMessagePart: Array<'time' | 'message'>;
  enabled: boolean;
};

interface LoggerStrategy {
  log(...args: any[]): void;
  error(...args: any[]): void;
  captureException(exception: Error, ...args: any[]): void;
}

class ConsoleLogger implements LoggerStrategy {
  log(...args: any[]): void {
    // eslint-disable-next-line no-console
    console.log(...args);
  }

  error(...args: any[]): void {
    console.error(...args);
  }

  captureException(exception: Error, ...args: any[]): void {
    console.error(exception, ...args);
  }
}

class LogRocketLogger implements LoggerStrategy {
  log(...args: any[]): void {
    LogRocket.info(...args);
  }

  error(...args: any[]): void {
    LogRocket.error(...args);
  }

  captureException(exception: Error, options: any): void {
    LogRocket.captureException(exception, options);
  }
}

class LoggerFactory {
  static createLogger(type: LoggerType): LoggerStrategy {
    switch (type) {
      case 'console':
        return new ConsoleLogger();
      case 'logrocket':
        return new LogRocketLogger();
      default:
        throw new Error(`Invalid logger type ${type}`);
    }
  }
}

/**
 * Ennabl Logger class.
 *
 * This logger class allows logging messages to multiple loggers (e.g., console, LogRocket)
 * based on the environment (development or production).
 *
 * @example - LocalStorage config
 * {
 *   "additionalLoggers": ["logrocket"],
 *   "skipMessagePart": ["time"],
 *   "enabled": true
 * }
 *
 * @example - Usages
 * Ennabl.log('path', { message: 'This is a log message' });
 * Ennabl.log(['path', 'to', 'log'], {
 *   message: 'This is a log message',
 * });
 * Ennabl.logOnce(['unique', 'path'], { message: 'This is a log message', props: { a: 1, b: 2} });
 * Ennabl.error('Error place', { error: 'Error details' });
 * Ennabl.captureException(new Error('Error message'));
 */
export class Ennabl {
  private static loggerStrategies: LoggerStrategy[] = [];
  private static initialized: boolean = false;
  private static defaultConfig: LoggerConfig = {
    additionalLoggers: [],
    skipMessagePart: [],
    enabled: IS_PROD_ENV,
  };

  private static lastLogOnceData: Map<string, Record<string, any>> = new Map();

  private static initializeLoggers() {
    if (!Ennabl.initialized) {
      Ennabl.loggerStrategies = Ennabl.getActiveLoggers().map(loggerType => LoggerFactory.createLogger(loggerType));
      Ennabl.initialized = true;
    }
  }

  private static getConfig(): LoggerConfig {
    const storedConfig: LoggerConfig = parseJSON(
      localStorage.getItem(LocalStorageKey.EnnablLoggerConfig),
      Ennabl.defaultConfig
    );
    const isObjectConfig = storedConfig && isObject(storedConfig);

    if (!isObjectConfig) {
      return Ennabl.defaultConfig;
    }

    return {
      ...Ennabl.defaultConfig,
      ...storedConfig,
      additionalLoggers: [
        ...new Set([...Ennabl.defaultConfig.additionalLoggers, ...(storedConfig?.additionalLoggers || [])]),
      ],
    };
  }

  private static formatTime(): string {
    return dayjs().format(DATE_FORMAT_ISO8601_LOCAL);
  }

  private static formatMessage(path: string | string[], message?: Record<string, any>): any[] {
    const config = Ennabl.getConfig();
    const parts: any[] = [];

    if (!config.skipMessagePart.includes('time')) {
      parts.push(Ennabl.formatTime());
    }

    if (Array.isArray(path)) {
      parts.push(...path.map(tag => `[${tag}]`));
    } else {
      parts.push(path);
    }

    if (!config.skipMessagePart.includes('message') && message) {
      parts.push(message);
    }

    return parts;
  }

  static getActiveLoggers(): Array<LoggerType> {
    const config = Ennabl.getConfig();
    const loggers: Array<LoggerType> = [];

    if (IS_DEV_ENV) {
      loggers.push('console');
    }

    if (IS_PROD_ENV) {
      loggers.push('logrocket');
    }

    return [...new Set([...loggers, ...config.additionalLoggers])];
  }

  static error(path: string | string[], errors?: Record<string, any>) {
    const config = Ennabl.getConfig();

    if (!config.enabled) {
      return;
    }

    Ennabl.initializeLoggers();

    const logMessage = Ennabl.formatMessage(path, errors);
    Ennabl.loggerStrategies.forEach(logger => logger.error(...logMessage));
  }

  static captureException(error: Error, ...args: any[]) {
    const config = Ennabl.getConfig();

    if (!config.enabled) {
      return;
    }

    Ennabl.initializeLoggers();
    Ennabl.loggerStrategies.forEach(logger => {
      logger.captureException(error, ...args);
    });
  }

  static log(path: string | string[], logs?: Record<string, any>) {
    const config = Ennabl.getConfig();

    if (!config.enabled) {
      return;
    }

    Ennabl.initializeLoggers();

    const logMessage = Ennabl.formatMessage(path, logs);
    Ennabl.loggerStrategies.forEach(logger => logger.log(...logMessage));
  }

  static logOnce(path: string | string[], logs: Record<string, any>) {
    const id = this.getUniqueLogId(path);

    const lastLog = Ennabl.lastLogOnceData.get(id);

    if (!lastLog || !shallow(lastLog, logs)) {
      Ennabl.log(path, logs);

      Ennabl.lastLogOnceData.set(id, logs);
    }
  }

  private static getUniqueLogId(path: string | string[]) {
    return Array.isArray(path) ? path.join('-') : path;
  }
}
