import {Navigate, Route, Routes} from 'react-router-dom';

import {lazyLoad} from 'utils/lazyLoadUtils';
import {PDF_ACCOUNT_LOCATION_V1, PDF_REPORTS_CARRIER_V1, PDF_REPORTS_LOSS_RUNS_V1} from 'core/constants/paths';

import {LoadingScreen} from 'components/LoadingScreen';

const lazyOpts = {
  fallback: <LoadingScreen />,
};

const CarrierV1Page = lazyLoad(
  () => import(/* webpackChunkName:'pdf-reports-carrier-v1-page' */ 'modules/pdfReports/pages/CarrierV1Page'),
  lazyOpts
);

const LocationV1Page = lazyLoad(
  () => import(/* webpackChunkName:'pdf-reports-account-location-v1-page' */ 'modules/pdfReports/pages/LocationV1Page'),
  lazyOpts
);

const LossRunsV1Page = lazyLoad(
  () => import(/* webpackChunkName:'pdf-reports-loss-runs-v1-page' */ 'modules/pdfReports/pages/LossRunsV1Page'),
  lazyOpts
);

export const Root = () => {
  return (
    <Routes>
      <Route path={`${PDF_REPORTS_CARRIER_V1}/*`} element={<CarrierV1Page />} />
      <Route path={`${PDF_ACCOUNT_LOCATION_V1}/*`} element={<LocationV1Page />} />
      <Route path={`${PDF_REPORTS_LOSS_RUNS_V1}/*`} element={<LossRunsV1Page />} />
      <Route path='*' element={<Navigate replace to='carrier/v1' />} />
    </Routes>
  );
};
