import {styled} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const SRoot = styled('div')<{inline?: boolean; padding: number}>(({inline, padding}) => ({
  padding,
  display: inline ? 'inline-flex' : 'flex',
  flexGrow: 1,
  alignItems: 'center',

  justifyContent: 'center',
  height: '100%',
}));

const SProgress = styled(CircularProgress)<{$color?: string}>(({theme, $color}) => ({
  color: $color || theme.palette.background.black, //
}));

const LoadingIndicator = ({
  color,
  size = 36,
  inline,
  padding = 20,
}: {
  inline?: boolean;
  size?: string | number | undefined;
  color?: string;
  padding?: number;
}) => {
  return (
    <SRoot inline={inline} padding={padding}>
      <SProgress size={size} $color={color} />
    </SRoot>
  );
};

export default LoadingIndicator;
