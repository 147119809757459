import {MatchPatternSet} from 'utils/hooks/useResolvedMatchParams';

export const enum IconKey {
  AI = 'ai',
  Home = 'home',
  Accounts = 'accounts',
  Bookmark = 'bookmark',
  CarrierAppetite = 'carrierAppetite',
  Producers = 'producers',
  Products = 'products',
  Markets = 'markets',
  Reports = 'reports',
  AccountManagers = 'accountManagers',
  AccountExecutives = 'accountExecutives',
  Industries = 'industries',
  Prospects = 'prospects',
  Union = 'union',
  Retention = 'retention',
  Renewals = 'renewals',
  Overview = 'overview',
  O360Score = 'o360score',
  Documents = 'documents',
  KPI = 'kpi',
  LossRuns = 'lossRuns',
}

export interface SectionItem {
  icon: IconKey;
  title: string;
  to: string;
  matchPattern?: MatchPatternSet[];
  isHidden?: boolean;
}

type Section = {
  title?: string;
  items: SectionItem[];
};

export type Divider = {
  divider: true;
};

export type SidebarSection = Section | Divider;
