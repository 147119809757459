import {darken, lighten} from 'color2k';

const colorVariables = {
  Global: {
    'background-hover': 'hsla(226, 25%, 96%, 1)',
    'background-active': 'hsla(207, 100%, 96%, 1)',
    'background-disabled': 'hsla(206, 100%, 90%, 1)',
    'background-empty': 'hsla(206, 25%, 92%, 1)',
    'background-primary': 'hsla(206, 100%, 50%, 1)',
    'background-primary-hover': 'hsla(206, 100%, 40%, 1)',
    'background-primary-active': 'hsla(206, 100%, 28%, 1)',
    'background-primary-disabled-danger': 'hsla(0, 94%, 94%, 1)',
    'background-secondary': 'hsla(0, 0%, 100%, 1)',
    'background-secondary-hover': 'hsla(228, 24%, 96%, 1)',
    'background-secondary-active': 'hsla(207, 100%, 96%, 1)',
    'background-secondary-active-danger': 'hsla(0, 100%, 98%, 1)',
    'background-secondary-disabled': 'hsla(0, 0%, 100%, 1)',
    border: 'hsla(228, 80%, 90%, 1)',
    'border-danger': 'hsla(360, 96%, 66%, 1)',
    'border-hover': 'hsla(206, 100%, 50%, 1)',
    'border-active': 'hsla(206, 100%, 50%, 1)',
    'border-disabled': 'hsla(206, 100%, 90%, 1)',
    'border-secondary-danger': 'hsla(360, 96%, 94%, 1)',
    heading: 'hsla(227, 26%, 42%, 1)',
    'icon-primary': 'hsla(0, 0%, 100%, 1)',
    'icon-secondary': 'hsla(206, 100%, 50%, 1)',
    'icon-secondary-danger': 'hsla(360, 96%, 66%, 1)',
    'icon-tertiary': 'hsla(206, 100%, 28%, 1)',
    'icon-tertiary-active': 'hsla(206, 100%, 50%, 1)',
    'icon-tertiary-active-danger': 'hsla(360, 96%, 66%, 1)',
    'icon-tertiary-danger': 'hsla(351, 84%, 36%, 1)',
    'icon-tertiary-hover': 'hsla(206, 100%, 40%, 1)',
    'text-active': 'hsla(206, 100%, 50%, 1)',
    'text-primary-default': 'hsla(0, 0%, 100%, 1)',
    'text-secondary': 'hsla(206, 100%, 50%, 1)',
    'text-secondary-disabled': 'hsla(206, 100%, 90%, 1)',
    'text-secondary-disabled-danger': 'hsla(360, 96%, 94%, 1)',
    'text-secondary-danger': 'hsla(360, 96%, 66%, 1)',
    'text-success': 'hsla(165, 65%, 50%, 1)',
    'text-ternary-danger': 'hsla(351, 84%, 36%, 1)',
    'text-tertiary': 'hsla(206, 100%, 28%, 1)',
    'text-tertiary-active': 'hsla(206, 100%, 50%, 1)',
    'text-tertiary-active-danger': 'hsla(0, 97%, 66%, 1)',
    'text-tertiary-danger': 'hsla(351, 84%, 36%, 1)',
    'text-tertiary-disabled': 'hsla(206, 100%, 90%, 1)',
    'text-tertiary-disabled-danger': 'hsla(0, 94%, 94%, 1)',
    'text-tertiary-hover': 'hsla(206, 100%, 40%, 1)',
    'text-tertiary-hover-danger': 'hsla(0, 96%, 44%, 1)',
    'text-warning': 'hsla(360, 96%, 66%, 1)',
  },
  Graph: {
    'background-carrier-10': 'hsla(45, 86%, 92%, 1)',
    'background-carrier-30': 'hsla(45, 86%, 84%, 1)',
    'background-carrier-100': 'hsla(45, 87%, 82%, 1)',
    'background-carrier-400': 'hsla(45, 86%, 70%, 1)',
    'background-carrier-600': 'hsla(45, 86%, 62%, 1)',
    'background-carrier-900': 'hsla(45, 86%, 50%, 1)',
    'background-carrier-1100': 'hsla(45, 87%, 42%, 1)',
    'background-carrier-1200': 'hsla(45, 87%, 38%, 1)',

    'background-client-10': 'hsla(206, 100%, 96%, 1)',
    'background-client-100': 'hsla(206, 100%, 72%, 1)',
    'background-client-600': 'hsla(206, 100%, 50%, 1)',
    'background-client-1200': 'hsla(206, 100%, 28%, 1)',

    'background-crosssell': 'hsla(260, 100%, 76%, 1)',

    'background-industry-10': 'hsla(21, 96%, 96%, 1)',
    'background-industry-30': 'hsla(21, 96%, 84%, 1)',
    'background-industry-100': 'hsla(21, 96%, 72%, 1)',
    'background-industry-400': 'hsla(21, 96%, 60%, 1)',
    'background-industry-600': 'hsla(21, 96%, 51%, 1)',
    'background-industry-900': 'hsla(21, 96%, 40%, 1)',
    'background-industry-1100': 'hsla(21, 96%, 32%, 1)',
    'background-industry-1200': 'hsla(21, 96%, 28%, 1)',

    'background-intermediary-10': 'hsla(223, 33%, 88%, 1)',
    'background-intermediary-30': 'hsla(223, 33%, 80%, 1)',
    'background-intermediary-100': 'hsla(223, 33%, 36%, 1)',
    'background-intermediary-600': 'hsla(223, 33%, 16%, 1)',

    // todo: possibly incorrect
    'background-premium-10': 'hsla(223, 33%, 88%, 1)',
    'background-premium-100': 'hsla(227, 91%, 40%, 1)',
    'background-premium-600': 'hsla(227, 90%, 16%, 1)',
    'background-premium-900': 'hsla(227, 90%, 4%, 1)',

    'background-producer-10': 'hsla(180, 92%, 94%, 1)',
    'background-producer-30': 'hsla(180, 92%, 84%, 1)',
    // todo: possibly incorrect
    'background-producer-100': 'hsla(180, 95%, 85%, 1)',
    'background-producer-300': 'hsla(180, 92%, 46%, 1)',
    'background-producer-400': 'hsla(180, 92%, 42%, 1)',
    'background-producer-600': 'hsla(180, 92%, 34%, 1)',
    'background-producer-900': 'hsla(180, 92%, 22%, 1)',
    'background-producer-1100': 'hsla(180, 93%, 16%, 1)',

    'background-product-10': 'hsla(206, 100%, 96%, 1)',
    'background-product-30': 'hsla(206, 100%, 84%, 1)',
    'background-product-100': 'hsla(206, 100%, 72%, 1)',
    'background-product-400': 'hsla(206, 100%, 60%, 1)',
    'background-product-600': 'hsla(206, 100%, 50%, 1)',
    'background-product-900': 'hsla(206, 100%, 40%, 1)',
    'background-product-1200': 'hsla(206, 100%, 28%, 1)',

    'background-revenue-10': 'hsla(165, 65%, 92%, 1)',
    'background-revenue-600': 'hsla(165, 65%, 50%, 1)',
    'background-revenue-1100': 'hsla(165, 65%, 30%, 1)',

    'background-salesize-600': 'hsla(260, 100%, 62%, 1)',

    'background-sizebands-600': 'hsla(80, 54%, 43%, 1)',

    'background-success-400': 'hsla(165, 65%, 58%, 1)',
    'background-success-600': 'hsla(165, 65%, 50%, 1)',

    'background-warning-400': 'hsla(360, 96%, 74%, 1)',
    'background-warning-600': 'hsla(360, 96%, 66%, 1)',
  },
  Tabs: {
    FontSize: {
      small: '14px',
    },
  },
};

export type ColorIndex = 80 | 70 | 60 | 50 | 40 | 30 | 20 | 10;

type DataTypeColorMap = Record<ColorIndex | 'primary', string>;

export const colorIndexes: ColorIndex[] = [80, 70, 60, 50, 40, 30, 20, 10];
const PRIMARY_INDEX = 50;

const getColorTransformationFn = (index: ColorIndex) => {
  switch (index) {
    case 10:
    case 20:
    case 30:
    case 40:
      return darken;
    case 60:
    case 70:
    case 80:
      return lighten;
    default:
      return (color: string) => color;
  }
};

/**
 * Get data type color by index, or take 'primary'
 */
const generateColorVariants = (color: string): DataTypeColorMap => {
  return colorIndexes.reduce((acc, alphaValue) => {
    const fn = getColorTransformationFn(alphaValue);
    return {
      ...acc,
      ...(alphaValue === PRIMARY_INDEX ? {primary: color} : {}),
      [alphaValue]: fn(color, Math.abs(alphaValue - PRIMARY_INDEX) / 100),
    };
  }, {} as DataTypeColorMap);
};

/**
 * @deprecated Please use colors from theme, if you have opportunity
 */
export const colorVariablesNext = {
  Background: {
    Grey: 'hsla(218, 67%, 98%, 1)',
    DarkGrey: 'hsla(218, 61%, 94%, 1)',
    White: 'hsla(0, 0%, 100%, 1)',
  },
  OutlinedHover: {
    Action: 'hsla(240, 100%, 95%, 1)',
  },
  divider: 'hsla(217, 68%, 94%, 1)',
  Text: {
    PrimaryBlack: 'hsla(0, 0%, 12%, 0.87)',
  },
  Products: {
    Commercial: 'hsla(196, 100%, 34%, 1)',
    Personal: 'hsla(196, 95%, 64%, 1)',
    Benefits: 'hsla(196, 95%, 22%, 1)',
  },
  dataTypes: {
    AccountMangers: generateColorVariants('hsla(313, 73%, 54%, 1)'),
    Accounts: generateColorVariants('hsla(255, 72%, 54%, 1)'),
    Benefits: generateColorVariants('hsla(288, 100%, 54%, 1)'),
    Book: generateColorVariants('hsla(209, 100%, 54%, 1)'),
    Carriers: generateColorVariants('hsla(47, 98%, 54%, 1)'),
    Clients: generateColorVariants('hsla(189, 100%, 54%, 1)'),
    Commercial: generateColorVariants('hsla(324, 100%, 54%, 1)'),
    Industries: generateColorVariants('hsla(340, 95%, 54%, 1)'),
    Intermediaries: generateColorVariants('hsla(25, 97%, 54%, 1)'),
    Personal: generateColorVariants('hsla(260, 73%, 54%, 1)'),
    Policies: generateColorVariants('hsla(176, 73%, 54%, 1)'),
    Premium: generateColorVariants('hsla(98, 32%, 54%, 1)'),
    Producers: generateColorVariants('hsla(254, 52%, 54%, 1)'),
    // These Unknown variants are generated incorrectly, HSL hues should be 186 but there are 190
    // The same problem occurs with the HEX format, there are 185 instead of 186.
    Unknown: generateColorVariants('hsla(186, 5%, 54%, 1)'),
    Revenue: generateColorVariants('hsla(156, 61%, 54%, 1)'),
  },
};

export const LocationReportBackground = '#1C2438';

export default colorVariables;
